import React from 'react';
import moment from 'moment';
import { REQUEST_API_FAILURE, REQUEST_API_START, REQUEST_API_SUCCESS, UPDATE_DATA } from '../actions/actions'

const initialState = {
  data: {
    users: [],
    projects: [],
    timeEntries: [],
    tasks: [],
    clients: [],
    bookings: [],
    slack: []
  },
  status: {
    loading: false,
    fetchedData: false,
    updatedAt: moment().format('x')
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case REQUEST_API_START: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: true
        }
      }
    }
    case REQUEST_API_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          fetchedData: true,
          updatedAt: moment().format('x')
        }
      }
    }
    case REQUEST_API_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          fetchedData: true
        }
      }
    }
    case UPDATE_DATA: {
      return {
        ...state,
        data: {
          ...state.data, ...action.payload
        }
      }
    }
    default: {
      return state;
    }
  }
}


export {initialState, reducer}
