import React from 'react'
import { OfficeBoardFun } from './OfficeBoardFun/OfficeBoardFun'
import { Berit } from './Berit/Berit'

const EasterEggs = () => {

	return (
		<div>
			<OfficeBoardFun/>
			<Berit/>
		</div>
	)
}

export { EasterEggs }
