import { createGlobalStyle } from 'styled-components/macro'
import { colors, typography, spacing } from '../utilities/variables'

const GlobalStyle = createGlobalStyle`

  html,
  body {
    font-family: ${typography.primaryFont};
  }
  
  body {
    color: ${colors.black};
    font-size: ${typography.sizes.base};
  }
  input{
    font-size: ${typography.sizes.base};
  }
  a {
    text-decoration: none;
  }
  p {
    margin-bottom: ${spacing.small};
  }
  
  h1,h2,h3,h4,h5,h6,strong {
  font-weight: bold;
  }
`

export { GlobalStyle }
