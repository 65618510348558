export const BASE_UNIT = 1

export const colors = {
  primary: '#DA2934',
  secondary: '#00574B',
  white: '#FFFFFF',
  yellow: '#FFD645',
  red: '#DA2934',
  purple: '#7625DE',
  purple02: 'rgba(118, 37, 222, 0.2)',
  green: '#2CB091',
  grey01: 'rgba(0,0,0,0.1)',
  grey02: 'rgba(0,0,0,0.2)',
  grey03: 'rgba(0,0,0,0.3)',
  grey04: 'rgba(0,0,0,0.4)',
  grey05: 'rgba(0,0,0,0.5)',
  black: '#000000',
  blueDark: '#292B52',
  tealDark: '#00574B',
  warning: '#EB0000',
  success: 'rgba(44, 176, 145, 1)',
  success05: 'rgba(44, 176, 145, 0.2)',
}

export const spacing = {
  xxxLarge: `${BASE_UNIT * 10}rem`,
  xxLarge: `${BASE_UNIT * 8}rem`,
  xLarge: `${BASE_UNIT * 6}rem`,
  large: `${BASE_UNIT * 3}rem`,
  medium: `${BASE_UNIT * 2}rem`,
  base: `${BASE_UNIT}rem`,
  small: `${BASE_UNIT / 2}rem`,
  xSmall: `${BASE_UNIT / 4}rem`,
  baseUnit: BASE_UNIT
}

export const typography = {
  primaryFont: 'MarkPro',
  lineHeight: '1.2',
  sizes: {
    xLarge: `${BASE_UNIT * 6}rem`,
    large: `${BASE_UNIT * 3}rem`,
    medium: `${BASE_UNIT * 2.5}rem`,
    base: `${BASE_UNIT * 2}rem`,
    small: `${BASE_UNIT * 1.5}rem`
  }
}

export const radius = {
  small: '.4rem',
  medium: '.8rem',
  large: '1.2rem'
}

export const mq = {
  tabletLandscapeDown: 1024,
  tabletLandscapeBelow: 1023,
  tabletPortraitDown: 768,
  tabletPortraitBelow: 767,
  mobileDown: 480
}
