import axios from 'axios'

axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}
axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN

const BASE_URL = process.env.REACT_APP_API_PATH

export const REQUEST_API_START = 'REQUEST_API_START'
export const requestApiStart = () => {
  return {type: REQUEST_API_START}
}

export const REQUEST_API_SUCCESS = 'REQUEST_API_SUCCESS'
export const requestApiSuccess = () => {
  return {type: REQUEST_API_SUCCESS}
}

export const REQUEST_API_FAILURE = 'REQUEST_API_FAILURE'
export const requestApiFailure = () => {
  return {type: REQUEST_API_FAILURE}
}

export const UPDATE_DATA = 'UPDATE_DATA'
export const updateData = (data) => {
  return {type: UPDATE_DATA, payload: data}
}

export const fetchData = (dispatch, deps) => {
  dispatch(requestApiStart())

  const requests = deps.map((dep) => {
    return axios.get(ENDPOINTS[dep])
  })
  axios.all(requests).then((results) => {
    dispatch(requestApiSuccess())
    let data = {}
    results.forEach((result, key) => {
      const dep = deps[key]
      data[dep] = result.data
    })
    dispatch(updateData(data))
  }).catch(error => {
    dispatch(requestApiFailure())
  })
}

const ENDPOINTS = {
  users: `${BASE_URL}/api/users`,
  projects: `${BASE_URL}/api/projects`,
  timeEntries: `${BASE_URL}/api/time-entries?with=project`,
  tasks: `${BASE_URL}/api/tasks`,
  clients: `${BASE_URL}/api/clients`,
  bookings: `${BASE_URL}/api/bookings`,
  slack: `${BASE_URL}/api/slack?channel=C0RFRR5BM` // Lansering
}
