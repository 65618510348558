import styled from 'styled-components/macro'
import { colors, typography } from '../../styles/utilities/variables'

const StyledLoader = styled.div`
  background: rgba(255,255,255,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
`

StyledLoader.Text = styled.p`
  color:${colors.black};
  font-size:${typography.sizes.large}
`

export default StyledLoader
