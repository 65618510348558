import React from 'react'
import StyledPassepartout from './style'

const Passepartout = props => {
  return (
    <StyledPassepartout>
      {props.children}
      <StyledPassepartout.Background />
      <StyledPassepartout.Frame>
        <StyledPassepartout.InnerBox />

        <StyledPassepartout.Top />
        <StyledPassepartout.Right />
        <StyledPassepartout.Bottom />
        <StyledPassepartout.Left />

        <StyledPassepartout.TopLeftCorner />
        <StyledPassepartout.TopRightCorner />
        <StyledPassepartout.BottomRightCorner />
        <StyledPassepartout.BottomLeftCorner />
      </StyledPassepartout.Frame>
    </StyledPassepartout>
  )
}
export { Passepartout }
