import React, { useEffect, useReducer } from 'react'
import axios from 'axios/index'
import { reducer, initialState } from '../reducers/reducers';
import { useReducerDevtools } from 'use-devtools-hook'
import { AppContext } from '../App'
import { accessToken } from '../utilities/Config'
import { fetchAllData } from '../actions/actions'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN
const BASE_URL = process.env.REACT_APP_API_PATH

const Context = (props) => {

  const stateReducer = useReducer(reducer, initialState)
  const [state, dispatch] = useReducerDevtools(
    stateReducer,
    initialState,
    'APP'
  )

  // // If not fetched Data do so
  // if (!state.fetchedData) {
  //   useEffect(() => {
  //     fetchAllData(dispatch)
  //   }, [state.fetchedData])
  // } else {
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       fetchAllData(dispatch)
  //     }, 36000000);
  //     return () => clearInterval(interval);
  //   }, [state.fetchedData])
  // }

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  )
}

export { Context }
