import React, {Suspense} from 'react'
import { Route, withRouter } from 'react-router-dom'
import './App.css'
import { Context } from './contexts/Context'
import { Loader } from './components/Loader/Loader'
import { ResetStyle } from './styles/base/reset'
import { GlobalStyle } from './styles/base/globalStyles'
import { Main } from './components/Main/Main'
import { Passepartout } from "./components/Passepartout/Passepartout"
import { EasterEggs } from './extra/EasterEggs'

// Context
export const AppContext = React.createContext()

// Lazy
const Weeks = React.lazy(() => import('./boards/Weeks'));
const Home = React.lazy(() => import('./boards/Home'));
const Users = React.lazy(() => import('./boards/Users'));
const User = React.lazy(() => import('./boards/User'));
const Wellness = React.lazy(() => import('./boards/Wellness'));
const Office = React.lazy(() => import('./boards/Office'));
const Screensaver = React.lazy(() => import('./boards/Screensaver'));
const Projects = React.lazy(() => import('./boards/Projects'));
const Project = React.lazy(() => import('./boards/Project'));

// Router
const WeeksWithRouter = withRouter(Weeks)
const HomeWithRouter = withRouter(Home)
const UsersWithRouter = withRouter(Users)
const UserWithRouter = withRouter(User)
const WellnessWithRouter = withRouter(Wellness)
const ProjectsWithRouter = withRouter(Projects)
const OfficeWithRouter = withRouter(Office)
const ScreensaverWithRouter = withRouter(Screensaver)
const ProjectWithRouter = withRouter(Project)

const App = () => {

  return (
    <Context>

      <ResetStyle/>
      <GlobalStyle />
	      <Main>
	        <Loader/>
	        <Suspense fallback={<Loader/>}>
	          <Route exact path='/' render={() => <HomeWithRouter/>}/>
	          <Route exact path='/office' render={() => <OfficeWithRouter/>}/>
	          <Route exact path='/users' render={() => <UsersWithRouter/>}/>
	          <Route exact path='/wellness' render={() => <WellnessWithRouter/>}/>
	          <Route exact path='/weeks' render={() => <WeeksWithRouter/>}/>
	          <Route exact path='/users/:id' render={() => <UserWithRouter/>}/>
			  <Route exact path='/screensaver' render={() => <ScreensaverWithRouter/>}/>
			  <Route exact path='/projects' render={() => <ProjectsWithRouter/>}/>
			  <Route exact path='/projects/:id' render={() => <ProjectWithRouter/>}/>
	        </Suspense>

	      </Main>
	    <EasterEggs />

    </Context>
  )
}

export { App }
