import React from 'react'
import StyledMain from './style'


const Main = (props) => {

  return (
    <StyledMain>
        {props.children}
    </StyledMain>
  )
}
export { Main }

