import React, {useContext} from 'react'
import StyledLoader from './style'
import { AppContext } from '../../App'


const Loader = (props) => {
  const [ { status } ] = useContext(AppContext);

  if (!status.loading) return null

  return (
    <StyledLoader>
        <StyledLoader.Text>Laddar...</StyledLoader.Text>
    </StyledLoader>
  )
}
export { Loader }

