import React, {useState, useContext, useEffect} from 'react'
import moment from 'moment'
import StyledOfficeBoardFun from './style'

const OfficeBoardFun = () => {

  const [isLeet, setIsLeet] = useState(false)
  const [isFood, setIsFood] = useState(false)

  let currentTime = moment();

  const foodStartTime = moment('12:00:00', "HH:mm:ss");
  const foodEndTime = moment('12:00:15', "HH:mm:ss");

  const leetStartTime = moment('13:37:00', "HH:mm:ss");
  const leetEndTime = moment('13:37:15', "HH:mm:ss");

  useEffect(() => {

    let interval = setInterval(() => {

      currentTime = moment();

      // Is Food
      if (!isFood && currentTime.isBetween(foodStartTime, foodEndTime)) {
        setIsFood(true)
      } else {
        if (isFood && currentTime.isAfter(foodEndTime)) {
          setIsFood(false)
        }
      }

      // Is LEET
      if (!isLeet && currentTime.isBetween(leetStartTime, leetEndTime)) {
        setIsLeet(true)
      } else {
        if (isLeet && currentTime.isAfter(leetStartTime)) {
          setIsLeet(false)
        }
      }


    }, 1000)
    return () => clearInterval(interval)
  }, [isLeet, isFood])


  return (
    <div>

      {isFood && (
        <StyledOfficeBoardFun.Food>
          TACOTIME
        </StyledOfficeBoardFun.Food>
      )}

      {isLeet && (
        <StyledOfficeBoardFun.Leet>
          L33T
        </StyledOfficeBoardFun.Leet>
      )}

    </div>
  )
}
export { OfficeBoardFun }
