import React, {useState, useEffect} from 'react'
import moment from 'moment'
import UIfx from 'uifx'

import berit_01 from './sounds/berit_gapadass.mp3';
import berit_02 from './sounds/berit_halla_pa_er.mp3';
import berit_03 from './sounds/berit_jada.mp3';
import berit_04 from './sounds/berit_jajustde.mp3';
import berit_05 from './sounds/berit_jattebra.mp3';
import berit_06 from './sounds/berit_oh_ja_da.mp3';

const zeroPad = (num) => {
	return ('00' + num).slice(-2)
}

const getTriggerTime = () => {
	let hour = 8 + Math.random() * (17 - 8) | 0;
	let minute = Math.random() * 60 | 0;
	let second = Math.random() * 60 | 0;

	let timeString = zeroPad(hour) + ':' + zeroPad(minute) + ':' + zeroPad(second)

	return moment(timeString, moment.HTML5_FMT.TIME_SECONDS)
}

const getTriggerTimeObject = () => {
	let currentTime = moment()
	let triggerTime = getTriggerTime()
	let hasPassed = currentTime.isSameOrAfter(triggerTime)
	return { time:triggerTime, hasPassed: hasPassed }
}

const getDailyTriggerTimes = () => {
	const triggersPerDay = 2

	let triggers = []
	for (let i = 0; i < triggersPerDay; i++) {
		triggers.push( getTriggerTimeObject() )
	}


	// Start debug

	console.log( '---' )

	// Debug trigger, 10 secs from page load
	// let debugTimeObject = {
	// 	hasPassed: false,
	// 	time: moment().add(10, 'seconds')
	// }
	//
	// triggers.push( debugTimeObject )

	triggers.sort((a, b) => (a.time.isAfter(b.time)) ? 1 : ((b.time.isAfter(a.time)) ? -1 : 0));

	for (let j = 0; j < triggersPerDay; j++) {
		let triggerTimeObject = triggers[j]
		console.log( triggerTimeObject.time.format(moment.HTML5_FMT.TIME_SECONDS) )
	}

	console.log( '---' )

	// End debug

	return triggers
}

const Berit = () => {

	const [sounds, setSounds] = useState([
		new UIfx(berit_01, {volume: 1}),
		new UIfx(berit_02, {volume: 1}),
		new UIfx(berit_03, {volume: 1}),
		new UIfx(berit_04, {volume: 1}),
		new UIfx(berit_05, {volume: 1}),
		new UIfx(berit_06, {volume: 1})
	])

	const [triggerTimes, setTriggerTimes] = useState(getDailyTriggerTimes())

	const generateNewTriggersTime = moment( '06:00:00', moment.HTML5_FMT.TIME_SECONDS )
	let currentTime = moment()

	const [newTriggersHasBeenGeneratedToday, setNewTriggersHasBeenGeneratedToday] = useState( currentTime.isSameOrAfter(generateNewTriggersTime) )

	useEffect(() => {

		let interval = setInterval(() => {

			currentTime = moment();

			// console.log( '---', currentTime.format(moment.HTML5_FMT.TIME_SECONDS));

			if (currentTime.isSameOrAfter(generateNewTriggersTime)){
				if( !newTriggersHasBeenGeneratedToday ){

					// Generate new triggers
					setTriggerTimes(getDailyTriggerTimes())

					setNewTriggersHasBeenGeneratedToday(true)
				}
			}else {
				setNewTriggersHasBeenGeneratedToday(false)
			}


			for (let [index, trigger] of triggerTimes.entries()) {

				if (currentTime.isSameOrAfter(trigger.time)) {
					if (!trigger.hasPassed) {

						// Play random Berit :)
						console.log( 'Berit' )
						let sound = sounds[Math.floor(Math.random()*sounds.length)]
						sound.play()

						let adjustedTriggerTimes = triggerTimes
						adjustedTriggerTimes[index].hasPassed = true
						setTriggerTimes( adjustedTriggerTimes )
						break;
					}
				}
			}

		}, 1000)

		return () => clearInterval(interval)
	}, [triggerTimes, newTriggersHasBeenGeneratedToday])


	return (
		<div></div>
	)
}
export { Berit }
