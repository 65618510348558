import styled from 'styled-components/macro'
import { spacing, colors, typography } from '../../styles/utilities/variables'

const StyledOfficeBoardFun = styled.div`

`

StyledOfficeBoardFun.Food = styled.div`
  background: green;
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height: 100%;
  text-align: center;
  color: white;
  line-height: 100vh;
  font-size:200px;
`

StyledOfficeBoardFun.Leet = styled.div`
  background: red;
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height: 100%;
  text-align: center;
  color: white;
  line-height: 100vh;
  font-size:200px;
`


export default StyledOfficeBoardFun
